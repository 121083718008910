import React, { useRef, useEffect } from 'react'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'

import { Power4 } from '../lib/gsap'
import useScrolledBelow from '../hooks/useScrolledBelow'
import { useTimeline } from '../hooks/animation'
import { media, rem, em, min, max } from '../styles/tools'
import theme from '../styles/theme'
import { isBrowser } from '../utils'

import SEO from '../components/SEO'
import Container from '../components/Container'
import Footer from '../components/Footer'
import Space from '../components/Space'
import ScrollArrow from '../components/ScrollArrow'
import Link from '../components/Link'
import { AutoScale } from '../components/Animated'
import {
  Headline,
  SectionTitle,
  WideText,
  BoldTextMedium,
  BoldTextSmall,
  WideTextUnderlined,
  Underline,
  SerifText,
  SocialLink,
  boldText,
} from '../styles/typography'

import resumePdf from '../assets/anthony-wiktor-resume-2019.pdf'

import jordan_mp4 from '../assets/jordan.mp4'
import payton_mp4 from '../assets/payton.mp4'
import webby_mp4 from '../assets/webby.mp4'
import usc_mp4 from '../assets/usc.mp4'

import aigaLogo from '../assets/affiliations/aigala.svg'
import tofcLogo from '../assets/affiliations/tofc.svg'
import imcLogo from '../assets/affiliations/imc.svg'
import designedLogo from '../assets/affiliations/designed.svg'
import uscLogo from '../assets/affiliations/usc.svg'

import FirstCentury from '../assets/client-logos/1stCentury.svg'
import Onestdibs from '../assets/client-logos/1stdibs.svg'
import abc from '../assets/client-logos/abc.svg'
import AChotels from '../assets/client-logos/AChotels.svg'
import adonit from '../assets/client-logos/adonit.svg'
import AIGA from '../assets/client-logos/AIGA-logo.svg'
import amc from '../assets/client-logos/amc.svg'
import azubu from '../assets/client-logos/azubu.svg'
import BatteryWharf from '../assets/client-logos/BatteryWharf.svg'
import berggruen from '../assets/client-logos/berggruen.svg'
import bravo from '../assets/client-logos/bravo.svg'
import butterfinger from '../assets/client-logos/butterfinger.svg'
import calpoly from '../assets/client-logos/calpoly.svg'
import cbsinteractive from '../assets/client-logos/cbsinteractive.svg'
import chadburry from '../assets/client-logos/chadburry.svg'
import coffeemate from '../assets/client-logos/coffeemate.svg'
import coldwellbanker from '../assets/client-logos/coldwellbanker.svg'
import dignityhealth from '../assets/client-logos/dignityhealth.svg'
import directtv from '../assets/client-logos/directtv.svg'
import equityoffice from '../assets/client-logos/equityoffice.svg'
import espn from '../assets/client-logos/espn.svg'
import fox from '../assets/client-logos/fox.svg'
import gatorade from '../assets/client-logos/gatorade.svg'
import hampton from '../assets/client-logos/hampton.svg'
import hollywoodmedia from '../assets/client-logos/hollywoodmedia.svg'
import hotpockets from '../assets/client-logos/hotpockets.svg'
import instantly from '../assets/client-logos/instantly.svg'
import itv from '../assets/client-logos/itv.svg'
import kraft from '../assets/client-logos/kraft.svg'
import lacare from '../assets/client-logos/lacare.svg'
import lionsgate from '../assets/client-logos/lionsgate.svg'
import logix from '../assets/client-logos/logix.svg'
import metro from '../assets/client-logos/metro.svg'
import motiv from '../assets/client-logos/motiv.svg'
import msnbc from '../assets/client-logos/msnbc.svg'
import nestle from '../assets/client-logos/nestle.svg'
import pressbros from '../assets/client-logos/pressbros.svg'
import rams from '../assets/client-logos/rams.svg'
import ronhami from '../assets/client-logos/ronhami.svg'
import santamonicaplaza from '../assets/client-logos/santamonicaplaza.svg'
import sapphire from '../assets/client-logos/sapphire.svg'
import smokeybones from '../assets/client-logos/smokeybones.svg'
import sothebys from '../assets/client-logos/sothebys.svg'
import tennischannel from '../assets/client-logos/tennischannel.svg'
import tmz from '../assets/client-logos/tmz.svg'
import toyota from '../assets/client-logos/toyota.svg'
import uw from '../assets/client-logos/uw.svg'
import voskos from '../assets/client-logos/voskos.svg'
import westfield from '../assets/client-logos/westfield.svg'
import whotels from '../assets/client-logos/whotels.svg'
import lafc from '../assets/client-logos/lafc.svg'
import jphro from '../assets/client-logos/jphro.svg'

const AnthonyImg = ({ src, ...props }) => {
  const tl = useTimeline({ delay: 0.75 })
  const shouldBeMuted = useScrolledBelow(100)

  useEffect(() => {
    tl.from(img.current, 2, { opacity: 0, ease: Power4.easeOut })
  }, [])

  const img = useRef()

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: -1,
        willChange: 'transform',
        backgroundColor: '#fff',
        transition: 'opacity .5s ease-out',
        opacity: shouldBeMuted ? 0.1 : 1,
        pointerEvents: 'none',
      }}
      {...props}
    >
      <div
        ref={img}
        css={{
          width: '100%',
          height: '100%',
          marginLeft: 'auto',
          opacity: 0.5,
          [media.tabletLg]: { width: '80%', opacity: 1 },
        }}
      >
        <Image
          critical
          fluid={src}
          imgStyle={{ objectPosition: '90% bottom' }}
          css={{ width: '100%', height: '100%' }}
        />
      </div>
    </div>
  )
}

const IntroText = props => {
  const tl = useTimeline({ delay: 0.75 })
  const isBelow = useScrolledBelow(100)

  useEffect(() => {
    const lines = headline.current.querySelectorAll('.line')

    tl.set(lines, { y: 50 })

    tl.staggerTo(
      lines,
      0.8,
      {
        y: 0,
        opacity: 1,
        ease: Power4.easeOut,
      },
      0.075,
      0
    )
  }, [])

  const headline = useRef()

  return (
    <h2
      ref={headline}
      style={{ color: isBelow ? 'white' : 'black' }}
      css={[
        boldText,
        {
          fontSize: '6vw',
          lineHeight: 0.9,
          [media.tabletLg]: { fontSize: '4.2vw' },
          [min(1525)]: { fontSize: rem(70) },
          transition: 'color .5s ease-out',
          '> .line': { opacity: 0 },
        },
      ]}
      {...props}
    >
      <div className="line">COMPETITION</div>
      <div className="line">AND CREATIVITY</div>
      <div className="line">RUNS HARD IN</div>
      <div className="line">HIS VEINS.</div>
    </h2>
  )
}

const hoverImgStyles = {
  display: 'block',
  maxWidth: 'initial',
  width: em(220),
  position: 'absolute !important',
  zIndex: 1,
  top: '50%',
  left: '50%',
  transition: '.3s',
  visibility: 'hidden',
  opacity: 0,
  'span:hover > &': {
    visibility: 'visible',
    opacity: 1,
  },
}

const HoverImg = ({ children, video, src, alt = '', ...props }) => (
  <span css={{ position: 'relative', cursor: 'default' }} {...props}>
    {children}
    {isBrowser &&
      window.matchMedia('(hover: hover)').matches &&
      (video ? (
        <video src={src} css={hoverImgStyles} autoPlay loop playsInline muted />
      ) : (
        <Image Tag="span" fixed={src} alt={alt} css={hoverImgStyles} />
      ))}
  </span>
)

export const query = graphql`
  query {
    anthonyImg: file(relativePath: { eq: "aw-about-img.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 920, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    hot100: file(relativePath: { eq: "web-designer-hot-100-trends.png" }) {
      ...hoverImg
    }
    webby: file(relativePath: { eq: "awards/webby.png" }) {
      ...hoverImg
    }
    tellys: file(relativePath: { eq: "awards/tellys.jpg" }) {
      ...hoverImg
    }
    communicator: file(relativePath: { eq: "awards/communicator.png" }) {
      ...hoverImg
    }
    dotcomm: file(relativePath: { eq: "awards/dotcomm.png" }) {
      ...hoverImg
    }
    hermes: file(relativePath: { eq: "awards/hermes.png" }) {
      ...hoverImg
    }
    how: file(relativePath: { eq: "awards/how.png" }) {
      ...hoverImg
    }
    w3: file(relativePath: { eq: "awards/w3.png" }) {
      ...hoverImg
    }
    aaa: file(relativePath: { eq: "awards/aaa.png" }) {
      ...hoverImg
    }
  }
`

const AboutPage = ({ data }) => (
  <div
    css={{ background: theme.colors.black, position: 'relative', zIndex: 0 }}
  >
    <SEO title="About" />
    <AnthonyImg src={data.anthonyImg.childImageSharp.fluid} />
    <Container
      as="header"
      css={{
        height: '154vw',
        position: 'relative',
        [media.tablet]: { height: '107vw' },
        [media.tabletLg]: {
          height: '100vh',
          minHeight: '54vw',
        },
      }}
    >
      <Space y={{ mobile: '110vw', tablet: '60vw', tabletLg: '21vw' }} />
      <IntroText css={{ position: 'relative' }} />
      <ScrollArrow
        title="Scroll down"
        href="#content"
        offset={100}
        css={{
          position: 'absolute',
          top: '85vw',
          left: rem(20 - 100),
          color: '#000',
          [media.tablet]: {
            top: 'auto',
            bottom: rem(60 - 20),
            left: '50%',
            transform: 'translate(-50%)',
          },
        }}
      />
    </Container>
    <Container
      as="section"
      id="content"
      css={{
        display: 'grid',
        gridTemplate: 'auto auto / 1fr',
        gridGap: rem(100),
        [media.tabletLg]: {
          gridTemplate: 'auto / 50% 1fr',
          gridGap: '10%',
        },
      }}
    >
      <div>
        <SerifText css={{ maxWidth: '33em' }}>
          Given that Anthony grew up in Chicago rooting for sports legends like{' '}
          <SerifText.Strong>
            <HoverImg video src={jordan_mp4}>
              MICHAEL JORDAN
            </HoverImg>
          </SerifText.Strong>{' '}
          and{' '}
          <SerifText.Strong>
            <HoverImg video src={payton_mp4}>
              WALTER PAYTON
            </HoverImg>
          </SerifText.Strong>{' '}
          that’s no surprise.
        </SerifText>
        <Space y={{ mobile: 20, tabletLg: 40 }} />
        <SerifText css={{ maxWidth: '33em' }}>
          Anthony brings that competitive spirit to his branding and design
          work, leading to shelves full of awards, and being listed in Web
          Designer Magazine's{' '}
          <SerifText.Strong>
            <HoverImg src={data.hot100.childImageSharp.fixed}>
              “HOT 100”
            </HoverImg>
          </SerifText.Strong>{' '}
          list twice.
        </SerifText>
        <Space y={{ mobile: 20, tabletLg: 40 }} />
        <SerifText css={{ maxWidth: '33em' }}>
          Case in point: Anthony's recent work for tech startup Motiv won a{' '}
          <SerifText.Strong>
            <HoverImg video src={webby_mp4}>
              WEBBY AWARD
            </HoverImg>
          </SerifText.Strong>{' '}
          and helped generate the company's first thousand sales with almost
          zero advertising.
        </SerifText>
        <Space y={{ mobile: 20, tabletLg: 40 }} />
        <SerifText css={{ maxWidth: '32em' }}>
          In addition to helping clients like ESPN and Netflix rise to victory
          against their competitors, Anthony shares his passion with future
          design professionals, leading creative workshops at{' '}
          <SerifText.Strong>
            <HoverImg video src={usc_mp4}>
              USC’S
            </HoverImg>
          </SerifText.Strong>{' '}
          Annenberg Digital Lounge, serving on the board of AIGA Los Angeles and
          as an expert panelist for the annual GlobalTrend Marketing Awards.
        </SerifText>
        <Space y={{ mobile: 20, tabletLg: 40 }} />
        <WideTextUnderlined as={Link} out href={resumePdf}>
          Download Resume
        </WideTextUnderlined>
      </div>
      <div>
        <WideText css={{ display: 'block' }}>EDUCATION</WideText>
        <Space y={20} />
        <BoldTextSmall>
          WOODBURY UNIVERSITY
          <br />
          BFA GRAPHIC DESIGN, 2006
        </BoldTextSmall>
        <Space y={50} />
        <WideText css={{ display: 'block' }}>EXPERIENCE</WideText>
        <Space y={20} />
        <BoldTextSmall>
          PRINCIPAL, CREATIVE DIRECTOR
          <br />
          AD VICTOREM, 2017-
        </BoldTextSmall>
        <Space y={25} />
        <BoldTextSmall>
          CREATIVE DIRECTOR
          <br />
          AZUBU.TV, 2015-2017
        </BoldTextSmall>
        <Space y={25} />
        <BoldTextSmall>
          INDEPENDENT CREATIVE DIRECTOR
          <br />
          CONSENSUS INC., 2013-2015
        </BoldTextSmall>
        <Space y={25} />
        <BoldTextSmall>
          CREATIVE DIRECTOR
          <br />
          STARMEN DESIGN GROUP, 2008-2014
        </BoldTextSmall>
        <Space y={25} />
        <BoldTextSmall>
          MARKETING ART DIRECTOR
          <br />
          SOTHEBY’S REAL ESTATE, 2006-2008
        </BoldTextSmall>
        <Space y={50} />
        <WideText css={{ display: 'block' }}>CONTACT</WideText>
        <Space y={20} />
        <ul
          css={{
            display: 'inline-grid',
            gridAutoFlow: 'column',
            gridGap: rem(20),
            [media.mobileLg]: { gridGap: rem(10) },
          }}
        >
          <li>
            <SocialLink
              small
              as={Link}
              out
              href="https://twitter.com/creativeanthony"
              style={{ '--color': '#05B2FF' }}
            >
              TW
            </SocialLink>
          </li>
          <li>
            <SocialLink
              small
              as={Link}
              out
              href="https://www.instagram.com/anthonydesigner/"
              style={{ '--color': '#E112FC' }}
            >
              IG
            </SocialLink>
          </li>
          <li>
            <SocialLink
              small
              as={Link}
              out
              href="https://www.linkedin.com/in/anthonywiktor/"
              style={{ '--color': '#094290' }}
            >
              LN
            </SocialLink>
          </li>
          <li>
            <SocialLink
              small
              as={Link}
              out
              href="https://www.behance.net/ad_victorem"
              style={{ '--color': '#1F86FF' }}
            >
              BE
            </SocialLink>
          </li>

          <li>
            <SocialLink
              small
              as={Link}
              out
              href="https://medium.com/advictorem"
              style={{ '--color': '#00A257' }}
            >
              M
            </SocialLink>
          </li>
        </ul>
        <Space y={20} />
        <BoldTextSmall>
          <Underline as="a" href="mailto:hello@anthonydesigner.com">
            Email
          </Underline>
        </BoldTextSmall>
      </div>
    </Container>
    <Space y={{ mobile: 100, tabletLg: 200 }} />
    <div css={{ backgroundColor: '#fff', color: '#000' }}>
      <Container as="section">
        <Space y={{ mobile: 100, tabletLg: 200 }} />
        <SectionTitle
          as="h1"
          viewBox="0 0 1282.7 132.5"
          d="M1194.1 36.5h86.5V5.6h-137.5V127h138.6V96.5h-87.6V79.9h82.1V51.8h-82.1V36.5zM1075.9 42h56.8c-1.5-32.8-32.8-41-78.4-41-45.9 0-78.7 8.2-78.5 41.1.2 27.2 21.4 34.8 47.8 37.7l39.4 4.4c12.6 1.4 15.5 4.1 15.5 8.7s-4.4 7-22.3 7c-15.8 0-24.8-2.2-25.7-12.9h-57.8c1.5 36.4 30.1 44.5 82.8 44.5 53.7 0 82.6-6.8 82.6-42.7 0-25.5-18.7-36.4-47.8-39.1l-42.2-3.9c-11.9-1-14.8-3.1-14.8-7.3s3.9-7 20.2-7c14.9.1 21.7 4.2 22.4 10.5zm-106.6 85V5.6h-51V127h51zM913.9 5.6H768V42h47.4v85h51V42h47.4V5.6h.1zM696.3 62.9h-38.1V42.1h38.1c9.7 0 12.9 3.4 12.9 10.5 0 7-3.4 10.3-12.9 10.3zm44.9 19.5v-1.7c15.6-4.1 24.8-13.4 24.8-33.3 0-26.5-13.1-41.8-54.1-41.8H607.2V127h51V92.6h34.5l19.7 34.3h56.3l-27.5-44.5zM513.7 36.5h86.5V5.6H462.7V127h138.5V96.5h-87.5V79.9h82.1V51.8h-82.1V36.5zM389.1 61h-36V38.6h36c10.5 0 13.9 3.7 13.9 11.4 0 7.4-3.5 11-13.9 11zm15.7-55.4H302.1V127h51V92.6h51.7c40.5 0 54.1-13.6 54.1-42.8-.1-29.9-13.2-44.2-54.1-44.2zm-153.6 58l46.6-58h-59.7L220 32.1 201.8 5.6h-59.7l46.6 58-52 63.4h62.2L220 96.5l21 30.5h62.2l-52-63.4zM52 36.5h86.5V5.6H1V127h138.6V96.5H52V79.9h82.1V51.8H52V36.5z"
          css={{ fontSize: '6.5vw' }}
        >
          EXPERTISE
        </SectionTitle>
        <div
          css={{
            display: 'grid',
            gridTemplateColumns: `repeat(auto-fit, minmax(${rem(280)}, 1fr))`,
            gridGap: rem(50),
            [media.tabletLg]: {
              gridGap: '10%',
            },
          }}
        >
          <div>
            <BoldTextMedium as="h2">Strategy</BoldTextMedium>
            <Space y={25} />
            <SerifText>
              Strategy is the underlying foundation of every great brand
              experience. Strategy that is centered on clarifying a brand’s
              message to inform their decisions and shape their actions.
            </SerifText>
            <Space y={{ mobile: 25, tabletLg: 50 }} />
            <BoldTextSmall css={{ lineHeight: 2.8 }}>
              RESEARCH & ANALYSIS
              <br />
              TREND & INSIGHT
              <br />
              CUSTOMER EXPERIENCE
              <br />
              BRAND PLATFORM & POSITIONING
              <br />
              GO-TO-MARKET PLANNING
              <br />
              CONTENT & MESSAGING
            </BoldTextSmall>
          </div>
          <div>
            <BoldTextMedium as="h2">Design</BoldTextMedium>
            <Space y={25} />
            <SerifText>
              From voice to tone to style, every expression matters. Brand
              design shapes a brand experience by creating identity touchpoints
              from design systems to key messages.
            </SerifText>
            <Space y={{ mobile: 25, tabletLg: 50 }} />
            <BoldTextSmall css={{ lineHeight: 2.8 }}>
              BRAND IDENTITY
              <br />
              VISUAL LANGUAGE
              <br />
              PACKAGING DESIGN
              <br />
              BRAND CAMPAIGN
              <br />
              SIGNATURE ASSETS
              <br />
              BRAND GUIDELINES
            </BoldTextSmall>
          </div>
          <div>
            <BoldTextMedium as="h2">Digital</BoldTextMedium>
            <Space y={25} />
            <SerifText>
              Creative functionality with intuitive design makes every
              interaction more meaningful. Embracing digital as an extension of
              the physical brand has the power to elevate the experience.
            </SerifText>
            <Space y={{ mobile: 25, tabletLg: 50 }} />
            <BoldTextSmall css={{ lineHeight: 2.8 }}>
              FRONT END WEB DESIGN
              <br />
              UI DESIGN
              <br />
              UX STRATEGY
              <br />
              APP DESIGN
              <br />
              CMS INTEGRATIONS
              <br />
              DIGITAL ADVERTISING
            </BoldTextSmall>
          </div>
        </div>
        <Space y={{ mobile: 100, tabletLg: 200 }} />
      </Container>
      <Container as="section">
        <SectionTitle
          as="h1"
          viewBox="0 0 1595.49 132.6"
          d="M1532.29 42h56.8c-1.5-32.8-32.8-41-78.4-41-45.9 0-78.7 8.2-78.5 41.1.2 27.2 21.4 34.8 47.8 37.7l39.4 4.4c12.6 1.4 15.5 4.1 15.5 8.7s-4.4 7-22.3 7c-15.8 0-24.8-2.2-25.7-12.9h-57.8c1.5 36.4 30.1 44.5 82.8 44.5 53.7 0 82.6-6.8 82.6-42.7 0-25.5-18.7-36.4-47.8-39.1l-42.2-3.9c-11.9-1-14.8-3.1-14.8-7.3s3.9-7 20.2-7c14.9.1 21.7 4.2 22.4 10.5zm-154.6-36.4v63.6h-1l-50.5-63.6h-58.7V127h47.9V62.7h1l51.2 64.3h58V5.6h-47.9zm-204.6 88.6c-23.1 0-33.3-10-33.3-27.9 0-17.8 10.2-27.9 33.3-27.9s33.3 10 33.3 27.9c0 17.8-10.2 27.9-33.3 27.9zm0-93.2c-60.3 0-89.1 20.9-89.1 65.3s28.7 65.3 89.1 65.3 89.1-20.9 89.1-65.3c-.1-44.4-28.8-65.3-89.1-65.3zm-94.6 126V5.6h-51V127h51zm-55.4-121.4h-145.8V42h47.4v85h51V42h47.4V5.6zm-227.6 74.5l15.8-35h1l15.8 35h-32.6zm53.7 46.9h54.6L845.29 5.6h-67L719.79 127h54.6l6.9-15.3h61l6.9 15.3zm-128.7 0V5.6h-51V127h51zm-55.9 0V91.3h-84.5V5.6h-51V127h135.5zm-143.1 0V5.6h-51V127h51zm-56-87.1V5.6h-137.8V127h51V91.6h82.1V59h-82.1V40h86.8v-.1zm-142.8 0V5.6h-137.8V127h51V91.6h82.1V59h-82.1V40h86.8v-.1zM77.29 80.1l15.8-35h1l15.8 35h-32.6zm53.7 46.9h54.6L127.09 5.6h-67L1.59 127h54.6l6.9-15.3h61l6.9 15.3z"
          css={{ fontSize: '5.2vw' }}
        >
          AFFILIATIONS
        </SectionTitle>
        <div
          css={{
            display: 'grid',
            gridTemplateColumns: `repeat(auto-fit, minmax(${rem(100)}, 1fr))`,
            gridGap: rem(80),
            alignItems: 'center',
            padding: rem(100),
            background: '#eee',
            [media.laptop]: {
              padding: rem(150),
              gridGap: '10%',
            },
          }}
        >
          <img src={aigaLogo} alt="" />
          <img src={tofcLogo} alt="" />
          <img src={imcLogo} alt="" />
          <img src={designedLogo} alt="" />
          <img src={uscLogo} alt="" />
        </div>
        <Space y={{ mobile: 100, tabletLg: 200 }} />
      </Container>
    </div>
    <Container as="section">
      <Space y={{ mobile: 100, tabletLg: 200 }} />
      <SectionTitle
        as="h1"
        viewBox="0 0 997.7 132.6"
        d="M934.5 42h56.8c-1.5-32.8-32.8-41-78.4-41-45.9 0-78.7 8.2-78.5 41.1.2 27.2 21.4 34.8 47.8 37.7l39.4 4.4c12.6 1.4 15.5 4.1 15.5 8.7s-4.4 7-22.3 7c-15.8 0-24.8-2.2-25.7-12.9h-57.8c1.5 36.4 30.1 44.5 82.8 44.5 53.7 0 82.6-6.8 82.6-42.7 0-25.5-18.7-36.4-47.8-39.1l-42.2-3.9c-11.9-1-14.8-3.1-14.8-7.3s3.9-7 20.2-7c14.9.1 21.7 4.2 22.4 10.5zM832.8 5.6H686.9V42h47.4v85h51V42h47.4V5.6h.1zm-198.2 0v63.6h-1L583.1 5.6h-58.6V127h47.9V62.7h1l51.2 64.3h58V5.6h-48zM430.9 36.5h86.5V5.6H379.9V127h138.5V96.5h-87.5V79.9H513v-28h-82.1V36.5zM372.3 127V5.6h-51V127h51zm-55.9 0V91.3h-84.5V5.6h-51V127h135.5zM116.3 54.5h58.8C174.2 20.2 147.4 1 88.5 1S1 21.9 1 66.3s28.7 65.3 87.5 65.3 86-19.2 86.9-56.4h-58.8c-1 10.7-6.5 19-28.1 19s-31.8-10-31.8-27.9c0-17.8 10.2-27.9 31.8-27.9s26.9 8.3 27.8 16.1z"
      >
        CLIENTS
      </SectionTitle>
      <div
        css={{
          display: 'grid',
          gridTemplateColumns: `repeat(3, 1fr)`,
          gridGap: '15vw',
          paddingTop: rem(80),
          alignItems: 'center',
          [media.tabletMd]: {
            gridTemplateColumns: `repeat(4, 1fr)`,
            gridGap: '10vw 12vw',
          },
          [media.laptop]: { gridTemplateColumns: `repeat(5, 1fr)` },
        }}
      >
        <AutoScale>
          <img src={FirstCentury} alt="" />
        </AutoScale>
        <AutoScale>
          <img src={Onestdibs} alt="" />
        </AutoScale>
        <AutoScale>
          <img src={abc} alt="" />
        </AutoScale>
        <AutoScale>
          <img src={AChotels} alt="" />
        </AutoScale>
        <AutoScale>
          <img src={adonit} alt="" />
        </AutoScale>
        <AutoScale>
          <img src={AIGA} alt="" />
        </AutoScale>
        <AutoScale>
          <img src={amc} alt="" />
        </AutoScale>
        <AutoScale>
          <img src={azubu} alt="" />
        </AutoScale>
        <AutoScale>
          <img src={BatteryWharf} alt="" />
        </AutoScale>
        <AutoScale>
          <img src={berggruen} alt="" />
        </AutoScale>
        <AutoScale>
          <img src={bravo} alt="" />
        </AutoScale>
        <AutoScale>
          <img src={butterfinger} alt="" />
        </AutoScale>
        <AutoScale>
          <img src={calpoly} alt="" />
        </AutoScale>
        <AutoScale>
          <img src={cbsinteractive} alt="" />
        </AutoScale>
        <AutoScale>
          <img src={chadburry} alt="" />
        </AutoScale>
        <AutoScale>
          <img src={coffeemate} alt="" />
        </AutoScale>
        <AutoScale>
          <img src={coldwellbanker} alt="" />
        </AutoScale>
        <AutoScale>
          <img src={dignityhealth} alt="" />
        </AutoScale>
        <AutoScale>
          <img src={directtv} alt="" />
        </AutoScale>
        <AutoScale>
          <img src={equityoffice} alt="" />
        </AutoScale>
        <AutoScale>
          <img src={espn} alt="" />
        </AutoScale>
        <AutoScale>
          <img src={fox} alt="" />
        </AutoScale>
        <AutoScale>
          <img src={gatorade} alt="" />
        </AutoScale>
        <AutoScale>
          <img src={hampton} alt="" />
        </AutoScale>
        <AutoScale>
          <img src={hollywoodmedia} alt="" />
        </AutoScale>
        <AutoScale>
          <img src={hotpockets} alt="" />
        </AutoScale>
        <AutoScale>
          <img src={instantly} alt="" />
        </AutoScale>
        <AutoScale>
          <img src={itv} alt="" />
        </AutoScale>
        <AutoScale>
          <img src={kraft} alt="" />
        </AutoScale>
        <AutoScale>
          <img src={lacare} alt="" />
        </AutoScale>
        <AutoScale>
          <img src={lafc} alt="" />
        </AutoScale>
        <AutoScale>
          <img src={lionsgate} alt="" />
        </AutoScale>
        <AutoScale>
          <img src={logix} alt="" />
        </AutoScale>
        <AutoScale>
          <img src={metro} alt="" />
        </AutoScale>
        <AutoScale>
          <img src={motiv} alt="" />
        </AutoScale>
        <AutoScale>
          <img src={msnbc} alt="" />
        </AutoScale>
        <AutoScale>
          <img src={nestle} alt="" />
        </AutoScale>
        <AutoScale>
          <img src={pressbros} alt="" />
        </AutoScale>
        <AutoScale>
          <img src={rams} alt="" />
        </AutoScale>
        <AutoScale>
          <img src={ronhami} alt="" />
        </AutoScale>
        <AutoScale>
          <img src={santamonicaplaza} alt="" />
        </AutoScale>
        <AutoScale>
          <img src={sapphire} alt="" />
        </AutoScale>
        <AutoScale>
          <img src={smokeybones} alt="" />
        </AutoScale>
        <AutoScale>
          <img src={sothebys} alt="" />
        </AutoScale>
        <AutoScale>
          <img src={tennischannel} alt="" />
        </AutoScale>
        <AutoScale>
          <img src={tmz} alt="" />
        </AutoScale>
        <AutoScale>
          <img src={toyota} alt="" />
        </AutoScale>
        <AutoScale>
          <img src={uw} alt="" />
        </AutoScale>
        <AutoScale>
          <img src={voskos} alt="" />
        </AutoScale>
        <AutoScale>
          <img src={westfield} alt="" />
        </AutoScale>
        <AutoScale css={{ [media.laptop]: { display: 'none' } }}>
          <img src={whotels} alt="" />
        </AutoScale>
        <AutoScale
          css={{
            display: 'none',
            [`@media (min-width: ${em(
              theme.breakpoints.tabletMd
            )}) and (max-width: ${em(theme.breakpoints.laptop)})`]: {
              display: 'block',
            },
          }}
        >
          <img src={jphro} alt="" />
        </AutoScale>
      </div>
      <Space y={{ mobile: 100, tabletLg: 200 }} />
    </Container>
    <Container as="section">
      <SectionTitle
        as="h1"
        viewBox="0 0 1036.19 132.5"
        d="M972.99 42h56.8c-1.5-32.8-32.8-41-78.4-41-45.9 0-78.7 8.2-78.5 41.1.2 27.2 21.4 34.8 47.8 37.7l39.4 4.4c12.6 1.4 15.5 4.1 15.5 8.7s-4.4 7-22.3 7c-15.8 0-24.8-2.2-25.7-12.9h-57.8c1.5 36.4 30.1 44.5 82.8 44.5 53.7 0 82.6-6.8 82.6-42.7 0-25.5-18.7-36.4-47.8-39.1l-42.2-3.9c-11.9-1-14.8-3.1-14.8-7.3s3.9-7 20.2-7c14.9.1 21.7 4.2 22.4 10.5zm-189.4-.7c20.2 0 29.2 6.8 29.2 25s-9 25-29.2 25h-23.5v-50h23.5zm13.9-35.7h-88.4V127h88.4c49.1 0 71.1-16.3 71.1-60.7s-21.9-60.7-71.1-60.7zm-162.5 57.3h-38.1V42.1h38.1c9.7 0 12.9 3.4 12.9 10.5 0 7-3.4 10.3-12.9 10.3zm44.9 19.5v-1.7c15.6-4.1 24.8-13.4 24.8-33.3 0-26.5-13.1-41.8-54.1-41.8h-104.7V127h51V92.6h34.5l19.7 34.3h56.3l-27.5-44.5zM438.39 80l15.8-35h1l15.8 35h-32.6zm53.6 47h54.6L488.09 5.6h-67L362.69 127h54.6l6.9-15.3h61l6.8 15.3zm-139.9 0l33.7-121.4h-51.2l-15.6 73.6h-1l-17.2-73.6h-53.4l-17 73.6h-1l-15.5-73.6h-51.5l32.7 121.4h61.7l16.8-72.8h1l16.8 72.8h60.7zM77.29 80l15.8-35h1l15.8 35h-32.6zm53.7 47h54.6L127.09 5.6h-67L1.59 127h54.6l6.9-15.3h61l6.9 15.3z"
      >
        AWARDS
      </SectionTitle>
      <div
        css={{
          marginTop: rem(80),
          overflowX: 'auto',
          WebkitOverflowScrolling: 'touch',
          [media.laptop]: {
            overflowX: 'initial',
          },
        }}
      >
        <table
          css={{
            width: '100%',
            minWidth: rem(800),
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: rem(18),
            lineHeight: 1.8,
            fontFamily: 'SourceSerifPro, serif',
            borderSpacing: 0,
            td: {
              padding: `${rem(15)} ${rem(0)}`,
            },
            'tr:not(:last-child) td': {
              borderBottom: 'solid 1px rgba(255, 255, 255, .2)',
            },
          }}
        >
          <tbody>
            <tr>
              <td>
                <BoldTextSmall>
                  <HoverImg src={data.webby.childImageSharp.fixed}>
                    WEBBY AWARDS
                  </HoverImg>
                </BoldTextSmall>
              </td>
              <td>People’s Voice</td>
              <td>Motiv Inc.</td>
              <td>2017</td>
            </tr>
            <tr>
              <td>
                <BoldTextSmall>
                  <HoverImg src={data.communicator.childImageSharp.fixed}>
                    HOW DESIGN AWARDS
                  </HoverImg>
                </BoldTextSmall>
              </td>
              <td>Reader’s Choice</td>
              <td>Ad Victorem</td>
              <td>2018</td>
            </tr>
            <tr>
              <td>
                <BoldTextSmall>
                  <HoverImg src={data.tellys.childImageSharp.fixed}>
                    The Telly Awards
                  </HoverImg>
                </BoldTextSmall>
              </td>
              <td>Silver</td>
              <td>Metro Los Angeles</td>
              <td>2018</td>
            </tr>
            <tr>
              <td>
                <BoldTextSmall>
                  <HoverImg src={data.dotcomm.childImageSharp.fixed}>
                    W3 AWARDS
                  </HoverImg>
                </BoldTextSmall>
              </td>
              <td>Silver</td>
              <td>Motiv Inc.</td>
              <td>2018</td>
            </tr>
            <tr>
              <td>
                <BoldTextSmall>
                  <HoverImg src={data.hermes.childImageSharp.fixed}>
                    COMMUNICATOR AWARDS
                  </HoverImg>
                </BoldTextSmall>
              </td>
              <td>Silver</td>
              <td>Motiv Inc.</td>
              <td>2017</td>
            </tr>
            <tr>
              <td>
                <BoldTextSmall>
                  <HoverImg src={data.how.childImageSharp.fixed}>
                    AMERICAN ADVERTISING AWARDS
                  </HoverImg>
                </BoldTextSmall>
              </td>
              <td>Silver</td>
              <td>Motiv Inc.</td>
              <td>2017</td>
            </tr>
            <tr>
              <td>
                <BoldTextSmall>
                  <HoverImg src={data.w3.childImageSharp.fixed}>
                    HERMES CREATIVE AWARDS
                  </HoverImg>
                </BoldTextSmall>
              </td>
              <td>Platinum</td>
              <td>Motiv Inc.</td>
              <td>2017</td>
            </tr>
            <tr>
              <td>
                <BoldTextSmall>
                  <HoverImg src={data.aaa.childImageSharp.fixed}>
                    DOTCOMM AWARDS
                  </HoverImg>
                </BoldTextSmall>
              </td>
              <td>Platinum</td>
              <td>Motiv Inc.</td>
              <td>2017</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Container>
    <Footer />
  </div>
)

export default AboutPage
